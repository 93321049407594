
function lazy() {
    setTimeout(function() {
        $(".lazy").trigger("show");
    }, 600);
}


$(document).ready(function() {
    var origin = window.location.origin;
    var pathname = window.location.pathname;
    var owl;
    var debug = false;

    //récupére les paramètres d'urls
    function param(param) {
        var vars = {};
        window.location.href.replace(location.hash, '').replace(
            /[?&]+([^=&]+)=?([^&]*)?/gi, // regexp
            function(m, key, value) { // callback
                vars[key] = value !== undefined ? value : '';
            }
        );

        if (param) {
            return vars[param] ? vars[param] : null;
        }
        return vars;
    }

    debug = param('debug');
    //#ici
    //step_param = param('step');

    if (debug == 'true') {
        var g = "color:#FE4F34;font-weight:bold;";
        console.log("%c                                  \n" +
            " ██████╗██╗     ██╗   ██╗██████╗  ██╗██╗  ██╗     \n" +
            "██╔════╝██║     ██║   ██║██╔══██╗███║██║  ██║      \n" +
            "██║     ██║     ██║   ██║██████╔╝╚██║███████║       \n" +
            "██║     ██║     ██║   ██║██╔══██╗ ██║╚════██║      \n" +
            "╚██████╗███████╗╚██████╔╝██████╔╝ ██║     ██║       \n" +
            " ╚═════╝╚══════╝ ╚═════╝ ╚═════╝  ╚═╝     ╚═╝       \n" +
            "                                                      \n\n" +
            "██████╗ ███████╗██████╗ ██╗   ██╗ ██████╗     ███╗   ███╗ ██████╗ ██████╗ ███████╗\n" +
            "██╔══██╗██╔════╝██╔══██╗██║   ██║██╔════╝     ████╗ ████║██╔═══██╗██╔══██╗██╔════╝\n" +
            "██║  ██║█████╗  ██████╔╝██║   ██║██║  ███╗    ██╔████╔██║██║   ██║██║  ██║█████╗  \n" +
            "██║  ██║██╔══╝  ██╔══██╗██║   ██║██║   ██║    ██║╚██╔╝██║██║   ██║██║  ██║██╔══╝  \n" +
            "██████╔╝███████╗██████╔╝╚██████╔╝╚██████╔╝    ██║ ╚═╝ ██║╚██████╔╝██████╔╝███████╗\n" +
            "╚═════╝ ╚══════╝╚═════╝  ╚═════╝  ╚═════╝     ╚═╝     ╚═╝ ╚═════╝ ╚═════╝ ╚══════╝\n\n" +
            "V1", g);
    }
    //lazyload
    $(".lazy").lazyload({
        event: "show"
    });

    setTimeout(function() {
        $(".lazy").trigger("show");
    }, 300);

    //SUPPORT IE
    Modernizr.on('backgroundcliptext', function(result) {
        if (!result) {
            jQuery('main').addClass('ie');
        }
    });


    Modernizr.on('webp', function(result) {
        if (debug == 'true') {
            console.log('---- WEBP ------');
            console.log(result);
        }
        if (result) {
            if (debug == 'true') {
                console.log('WEBP supporté');
            }
            // Webp supporté
            /*            jQuery('.lazy').each(function(index, el) {
                            var url = jQuery(el).attr('data-original');
                            var svg = url.search('.svg');
                            if (svg == -1) {
                                var image = url.replace('.png', '.webp').replace('.jpeg', '.webp').replace('.jpg', '.webp');
                                $.get("./" + image)
                                    .done(function(data) {
                                        console.log('success');
                                        console.log(image);
                                        //  console.log(data);
                                        if (data.length) {
                                            jQuery(el).attr('data-original', image);
                                        }
                                    })
                            }
                        });*/
        } else {
            if (debug == 'true') {
                console.log('WEBP non supporté');
            }
            // Webp non supporté
            jQuery('.lazy').each(function(index, el) {
                var url = jQuery(el).attr('data-original');
                if (debug == 'true') {
                    console.log('data of image : ' + url);
                }
                var webp = url.search('.webp');
                if (webp != -1) {

                    var image = url.replace('.webp', '.png');
                    if (debug == 'true') {
                        console.log('find webp ');
                        console.log('webp search result : ' + webp);
                        console.log('image url replace : ' + image);
                    }
                    $.get("./" + image)
                        .done(function(data, message) {
                            if (debug == 'true') {
                                console.log('png for url :' + url);
                            }

                            if (data.length) {
                                jQuery(el).attr('data-original', image);
                                jQuery(el).lazyload();
                                setTimeout(function() {
                                    jQuery(el).trigger("show");
                                }, 150);
                            }
                        })
                        .fail(function() {
                            var image = url.replace('.webp', '.jpg');
                            $.get("./" + image)
                                .done(function(data) {
                                    if (debug == 'true') {
                                        console.log('jpg for url :' + url);
                                    }
                                    if (data.length) {
                                        jQuery(el).attr('data-original', image);
                                        jQuery(el).lazyload();
                                        setTimeout(function() {
                                            jQuery(el).trigger("show");
                                        }, 150);

                                    }
                                });

                        })
                }
            });
            if (debug == 'true') {
                console.log('---- FIN WEBP ------');
            }
        }
    });

    // MENU COLOR
    var nextsection = jQuery('nav').next();
    if (jQuery(nextsection).hasClass('cover') || jQuery(nextsection).hasClass('covertri') || jQuery(nextsection).hasClass('events')) {
        jQuery("#header").addClass('overlay');
        jQuery("#header .button").addClass('blanc');
        jQuery("#header .button").removeClass('transparent');
    } else {
        jQuery("#header").removeClass('overlay');
        jQuery("#header .button").addClass('transparent');
        jQuery("#header .button").removeClass('blanc');
    }

    const locationUrl = window.location.toString();
    jQuery.get(locationUrl+'/image', function(data) {
        jQuery('#valid_img').prop('checked', data.allow);

        jQuery('#valid_img').change(function(e) {
            jQuery.post(locationUrl+'/image', {
                allow: $(this).prop('checked') ? 1 : 0,
            });
        });
    });

    //MENU SCROLL
    jQuery(document).scroll(function() {
        var scroll = jQuery('html,body').scrollTop();
        if (scroll > 0) {
            if (!jQuery('#header').hasClass('scrolled')) {
                jQuery('#header').addClass('scrolled');
            }

            setTimeout(function() {
                $(".lazy").trigger("show");
            }, 300);

        } else {

            jQuery('#header').removeClass('scrolled');
        }
    });

    //MENU BURGER
    var forEach = function(t, o, r) {
        if ("[object Object]" === Object.prototype.toString.call(t))
            for (var c in t) Object.prototype.hasOwnProperty.call(t, c) && o.call(r, t[c], c, t);
        else
            for (var e = 0, l = t.length; l > e; e++) o.call(r, t[e], e, t)
    };
    var hamburgers = document.querySelectorAll(".hamburger");
    if (hamburgers.length > 0) {
        forEach(hamburgers, function(hamburger) {
            hamburger.addEventListener("click", function() {
                this.classList.toggle("is-active");
                jQuery('.menu').toggleClass("is-active");
                jQuery('#header').toggleClass("is-active");
            }, false);
        });
    }

    //CARD WITH MASONRY
    jQuery('.grid').masonry({
        itemSelector: '.block',
        percentPosition: true,
        columnWidth: '.grid-sizer'
    })

    jQuery('.retro .grid').masonry({
        itemSelector: '.block',
        percentPosition: true,
        columnWidth: '.grid-sizer'
    })

    // HOME SCROLL
    var name = jQuery('section').attr('name');
    if (name != undefined) {
        jQuery('body').append("<div id='switch'></div>");
        jQuery('section').each(function(index, el) {
            var name = jQuery(this).attr('name');
            if (name != undefined && name != '') {
                if (index == 0) {
                    var active = 'active';
                    var scroll = 0;
                } else {
                    var active = '';
                    var scroll = jQuery(this).offset().top;
                }

                jQuery('#switch').append('<div class="round ' + active + '" scroll="' + scroll + '"><span>' + name + '</span></div>');
            }
        });

        // AU CLIQUE
        jQuery('#switch .round').click(function(event) {
            var scroll = jQuery(this).attr('scroll');
            jQuery('html, body').animate({
                scrollTop: scroll
            }, '500');
            jQuery('#switch .round').removeClass('active');
            jQuery(this).addClass('active');
        });

        //AU SCROLL
        var scroll_init = jQuery('html,body').scrollTop();
        find_scroll(scroll_init);

        jQuery(document).scroll(function() {
            var scroll_init = jQuery('html,body').scrollTop();
            find_scroll(scroll_init);
        });

        function find_scroll(value) {
            var total;
            var juste = jQuery(document).height();
            var elem;
            jQuery('#switch .round').each(function(index, el) {
                var total = jQuery(document).height();
                var that = jQuery(this);
                var scroll_elem = jQuery(that).attr('scroll');
                if (value >= scroll_elem) {
                    total = value - scroll_elem - 91;
                }

                if (total <= juste) {
                    juste = total;
                    elem = jQuery(that);
                }
            });
            jQuery('#switch .round').removeClass('active');
            jQuery(elem).addClass('active');
        }
    }

    // COVER SLIDER
    jQuery('.cover .bullet .side:first-child').addClass('active');

    if (jQuery('.cover .bullet').length) {
        var timer = new Timer(function() {
            cover();
        }, 10000);
    }

    jQuery('.cover .bullet .side').click(function(event) {
        if (!jQuery(this).hasClass('active')) {
            cover();
            timer.reset(10000);
        }
    });

    function cover() {
        //lazy load cover
        $(".lazy").trigger("show");
        change('.cover', '.actu.active');
        change('.cover', '.side.active');
        change('.cover', '.bg.active', 'none');
    }

    function change(container, element, none) {

        var el = jQuery(container).find(element);
        if (debug == 'true') {
            /*    console.log('---- CHANGE COVER SLIDER ------');
                console.log(jQuery(el));
                console.log('---- FIN CHANGE COVER SLIDER ------');*/
        }


        var classelem = jQuery(el)[0].classList[0];
        var next = jQuery(el).next('.' + classelem);

        if (!next.length) {
            next = jQuery(container).find('.' + classelem).first();
        }

        jQuery(next).addClass('active');

        if (none != undefined) {
            setTimeout(function() {
                jQuery(el).removeClass('active');
            }, 500);
        } else {
            jQuery(el).removeClass('active');
        }
    }

    function Timer(fn, t) {
        var timerObj = setInterval(fn, t);
        this.stop = function() {
            if (timerObj) {
                clearInterval(timerObj);
                timerObj = null;
            }
            return this;
        }
        this.start = function() {
            if (!timerObj) {
                this.stop();
                timerObj = setInterval(fn, t);
            }
            return this;
        }
        this.reset = function(newT) {
            t = newT;
            return this.stop().start();
        }
    }

    // BALADE STEP
    //step count
    var count = 0;
    jQuery('.filtre.step').each(function(index, el) {
        count++;
    });

    function random(min, max) { // min and max included
        return Math.floor(Math.random() * (max - min + 1) + min);
    }

    function percent(a, b) {
        var c = (parseFloat(a) * parseFloat(b)) / 100;
        return parseFloat(c);
    }

    // COURBE
    if (jQuery('#courbe').length) {
        var base = '../images/static/Courbes';
        var imgURL = base + "/courbe" + count + "_pointilles.svg";
        svgreplace(imgURL, '.balade .row.visuel', 'line point', 'point');
        var imgURL = base + "/courbe" + count + "_plein.svg";
        var myVivus = new Vivus(
            'courbe', {
                type: 'oneByOne',
                duration: 1000,
                file: imgURL,
                start: 'manual',
                pathTimingFunction: Vivus.LINEAR,
                animTimingFunction: Vivus.LINEAR
            });

        function nextpoint(point, count, direction) {
            var map = myVivus.map;
            var vivus_sens;
            var vivus_stop;
            var i = 0;
            if (direction == 'right') {
                vivus_sens = 1;
                vivus_stop = 1;
                a = 1;
            } else {
                vivus_sens = -1;
                vivus_stop = 0;
                a = 0;
            }

            myVivus.play(vivus_sens);
            jQuery.each(map, function(index, value) {
                if (point - a == i) {
                    var verfiprogress = setInterval(function() {
                        var stop = value.progress;
                        if (stop == vivus_stop) {
                            myVivus.stop();
                            clearInterval(verfiprogress);
                            var that = myVivus.el;
                            active_elem_svg(that, '.st1', point - 1, false, direction);
                            active_elem_svg(that, '.st2', point - 1, true, direction);
                        }
                    }, 50);
                }
                i++;
            });
        }

        var historique = [];

        function active_elem_svg(that, elem, point, next, direction) {

            jQuery(that).find(elem).each(function(index, el) {
                if (index == point) {
                    jQuery(el).addClass('active');
                    //MOBILE COURBE
                    if (responsive < 768) {
                        var position = jQuery(el).offset().left;
                        var width = jQuery(document).width();

                        if (direction == 'right') {
                            var deplace = jQuery(el).offset().left - 100;

                            if (next == true) {
                                historique.push(deplace);
                            }

                            if (position + width / 2 >= width) {
                                jQuery('.balade svg').css('left', '-' + deplace);
                            }

                        } else {
                            jQuery('.balade svg').css('left', '-' + historique[index - 1]);
                        }
                    }

                    if (next == true) {
                        var etape_text = jQuery('.nav nav h4.active').text();
                        // etape_text = etape_text.split(' ');
                        var count = point + 1;
                        jQuery(el).next().text('étape ' + count).addClass('active');
                        jQuery(el).next().next().text(etape_text).addClass('active rouge');
                    }

                }
            });
        }

        function svgreplace(imgURL, append, classe, id) {
            jQuery.get(imgURL, function(data) {
                var $svg = jQuery(data).find('svg');
                $svg = $svg.attr('id', id);
                $svg = $svg.attr('class', classe + ' replaced-svg');
                $svg = $svg.removeAttr('xmlns:a');
                jQuery(append).append($svg);
            }, 'xml');
        }

        // COMPTEUR
        if (count <= 9) {
            var forme = '0';
        } else {
            var forme = '';
        }

        jQuery('.count_steps span').text('/ ' + forme + count);

        //step
        var e = 1
        setTimeout(function() {
            nextpoint(e, count, 'right');
        }, 300);

        jQuery('.balade .nav i').click(function(event) {

            var sens = jQuery(this);
            if (sens.hasClass('fa-chevron-right')) {
                if (e < count) {
                    e++;
                }
                var direction = 'right';
            } else {
                e--;
                var direction = 'left';
            }

            if (e <= count) {
                if (e <= 9) {
                    var forme = '0';
                } else {
                    var forme = '';
                }

                if (e == count) {
                    jQuery('.balade .nav i.fa-chevron-right').addClass('none');
                } else {
                    jQuery('.balade .nav i.fa-chevron-right').removeClass('none');
                    if (e > 1) {
                        jQuery('.balade .nav i.fa-chevron-left').removeClass('none');
                    } else {
                        jQuery('.balade .nav i.fa-chevron-left').addClass('none');
                    }
                }
                jQuery('.step.active').each(function(index, el) {
                    var actual = jQuery(this);
                    setTimeout(function(index) {
                        if (sens.hasClass('fa-chevron-right')) {
                            var next = jQuery(actual).next('.step').addClass('active');
                        } else {
                            var next = jQuery(actual).prev('.step').addClass('active');
                        }

                        if (next.hasClass('step')) {
                            jQuery(actual).removeClass('active');
                        }
                    }, index * 150);
                });

                setTimeout(function() {
                    var chiffre = jQuery('.step.active').attr('step');
                    jQuery('.count_steps u').text(forme + chiffre);
                }, 300);

                setTimeout(function(index) {
                    var mybalade = $("h4.step.active").text();
                    $(".popin").attr('balade', mybalade);
                }, 500);

                nextpoint(e, count, direction);
            }
        });
    }

    //function prend + grande hauteur des différents éléments
    function equalheight(element, heightcontent) {
        var o_h = 0;
        jQuery(element).each(function(index, el) {
            var a_h = jQuery(this).height();
            if (a_h > o_h) {
                o_h = a_h;
            }
        });
        jQuery(heightcontent).height(o_h);
    }

    if (jQuery('.retro .block').length) {

        var o_h = 0;
        jQuery('.block .content h5').each(function(index, el) {
            var a_h = jQuery(this).height();
            if (a_h > o_h) {
                o_h = a_h;
            }
        });
        jQuery('.retro .block .content').height(o_h + 50);

    }

    setTimeout(function() {

        //gestion des hauteurs des cards event / articles


        //hauteur des titres articles de la page 404
        equalheight('.error .row.articles .article p', '.error .row.articles .article p');

        //height of bloc balade
        equalheight('.balade .row.description .content_description .content_step .step', '.balade .row.description .content_description .content_step');

        //height of block nav actu
        equalheight('.actus .side h4', '.actus .side h4');
        equalheight('.actus .side p', '.actus .side p');

        //height titre chiffre page histoire
        equalheight('.chiffres .container .row h2', '.chiffres .container .row h2');

        //height texte block icons page assurance
        equalheight('.block-icons .content-icons .content p', '.block-icons .content-icons .content p');

        //height titre des listes balades

        equalheight('.liste-balade .content-block .block .content .nom', '.liste-balade .content-block .block .content .nom');

        equalheight('.register .content form .content_step .step .row.type .button', '.register .content form .content_step .step .row.type .button');
    }, 300);

    if (jQuery('.resume')) {
        var resume_h = jQuery('.resume p').each(function(index, el) {
            if (jQuery(this).height() >= 236) {
                jQuery('.resume .gallerie').css('margin-top', '20px');
            }
        });
    }

    // carousel
    var responsive = jQuery('body').width();
    // focus, center, items, loop, margin, width, nav, auto
    owl('.resume .gallerie', true, 4, true, 0, true, false, true, false);
    owl('.liste-balade.owl-carte .content-block', false, 4, false, 30, true, true, false, true);
    if (responsive <= 1200) {
        owl('.gallerie .carte-conteneur', true, 1, true, 30, false, true, true, false);
        owl('.actuother .content-block .text-center', true, 3, true, 30, true, true, false, false);

    }

    if (responsive > 1024) {
        owl('.avis .content', false, 3, true, 40, false, true, true);
    } else {
        owl('.avis .content', false, 1, true, 40, false, true, true);
    }

    if (responsive <= 1024) {
        if (jQuery('.formule .tab-formules').length) {
            formulesassurance();
            jQuery('.formule .tab-formules div').first().remove();
        }
        owl('.formule .tab-formules', true, 1, false, 0, false, true, false);
        owl('.liste-balade .content-block', false, 4, false, 30, true, false, false, true);

    }

    if (responsive <= 480) {
        //   owl('.liste-balade .content-block', true, 1, true, 30, true, true, false);
        owl('.carte-conteneur', true, 3, true, 40, true, true, false);
    }

    function post_relative(element, element_actif, marginposition) {
        element_p = jQuery(element_actif).offset().top;
        element_h = jQuery(element_actif).outerHeight();
        element_p = element_p + element_h + marginposition;

        $(element).offset({ top: element_p });
        /*.css("transition", "all 0.1s ease")*/
    }

    function formulesassurance() {
        setTimeout(function() {
            var item_actif = jQuery('.tab-formules .owl-item.active');
            var element_parent_hgt = jQuery('.formule .owl-carousel');
            var item_actif_hgt = item_actif.height();
            element_parent_hgt.height(item_actif_hgt + 50);
            post_relative('.formule .tab-formules .owl-dots', '.tab-formules .owl-item.active', 120);
        }, 150);
    }

    if (jQuery('.chrono .row .carroussel .step .data-content').length) {
        $('.chrono .row .carroussel .step .data-content').each(function() {
            var dateblock = ($(this).find('.date-block'));
            $(dateblock).each(function() {
                var icon = $(this).find('.iconrnd').offset().top;
                var hr = $(this).find('.hr');
                var hrlast = $(this).find('.hr.last');
                var hrlastoneexcept = $(this).find('.hr.lastoneexcept');
                var text = $(this).find('.h3.col-7.col-md-8').height();

                if (responsive >= 770) {
                    $(hr).each(function() {
                        $(hr).height(text + 30);
                        $(hrlast).height(text);
                        $(hrlastoneexcept).height(text);
                    });

                } else if (768 > responsive && responsive > 395) {
                    $(hr).each(function() {
                        $(hr).height(text / 4);
                        $(hrlast).height(text / 5);
                        $(hrlastoneexcept).height(text / 4);
                    });
                } else {
                    $(hr).each(function() {
                        $(hr).height(text / 4);
                        $(hrlast).height(text / 5);
                        $(hrlastoneexcept).height(text / 4);
                    });
                }
            });

        }, 500);
    };

    function histochrono() {
        setTimeout(function() {

            var item_actif = jQuery('.chrono .owl-item.active .step .data-content');
            var element_parent_hgt = jQuery('.chrono .owl-item.active .step');
            var item_actif_hgt = item_actif.height();
            element_parent_hgt.height(item_actif_hgt + 85);
            jQuery('.chrono .owl-stage').height(item_actif_hgt + 100);
            if (debug == 'true') {
                console.log(jQuery('.chrono .owl-carousel').height());
            }
            post_relative('.chrono .owl-next', '.chrono .owl-item.active', -120);
            jQuery('.chrono').height(item_actif_hgt + 200);

        }, 450);
        $('.chrono .row .carroussel .step .data-content').each(function() {
            var dateblock = ($(this).find('.date-block'));
            $(dateblock).each(function() {
                var icon = $(this).find('.iconrnd').offset().top;
                var hr = $(this).find('.hr');
                var hrlast = $(this).find('.hr.last');
                var hrlastone = $(this).find('.hr.lastone');
                var hrlastoneexcept = $(this).find('.hr.lastoneexcept');
                var text = $(this).find('.h3.col-7.col-md-8').height();
                $(hr).each(function() {

                    $(hr).height(text + 55);
                    $(hrlast).height(text + 10);
                    $(hrlastone).height(text + 35);
                    $(hrlastoneexcept).height(text - 5);

                });
            });

        }, 300);
    }

    var owl;

    function owl(focus, center, items, loop, margin, width, nav, auto, arrow) {
        if (jQuery(focus).length) {
            jQuery(focus).addClass('owl-carousel owl-theme');
            setTimeout(function() {
                var owl = jQuery(focus).owlCarousel({
                    center: center,
                    items: items,
                    loop: loop,
                    margin: margin,
                    autoWidth: width,
                    dots: nav,
                    autoplay: auto,
                    nav: arrow
                });

                jQuery('.owl-item .block').each(function(index, el) {
                    var classes = jQuery(this).attr('class');
                    var split = classes.split('block');
                    /* var carousel = jQuery(this).find('.carousel');
                     if(carousel.length){
                        var carouselvar = 'carouseldata';
                        jQuery(this).addClass('carouseldata');
                     }*/
                    jQuery(this).parent().addClass(split[1]);
                });


                owl.on('changed.owl.carousel', function(event) {
                    if (jQuery('.formule .tab-formules').length) {
                        formulesassurance();

                    }
                    if (jQuery('.chrono .owl-carousel').length) {
                        histochrono();
                    }
                })
            }, 100);
        }
    }

    //etape 
    if (jQuery('.etape .row').length) {
        var hb = window.screen.height;
        var he = jQuery('.flex').height();
        if (he < hb - 200) {
            if (responsive <= 1650) {
                $("#sticker").sticky({ topSpacing: 100 });
            } else {
                $("#sticker").sticky({ topSpacing: 258 });
            }
        }


    }

    if (jQuery('.cover nav .content').length) {
        jQuery('.cover nav').each(function(index, el) {
            var that = jQuery(this);
            var nav_scroll = $('.cover nav .content').offset();
            jQuery(document).scroll(function() {
                var scroll = jQuery('html,body').scrollTop();
                if (scroll >= nav_scroll.top - 200) {
                    jQuery(that).addClass('post_sticky');
                    setTimeout(function() {
                        jQuery(that).addClass('sticky');
                    }, 100);
                    setTimeout(function() {
                        jQuery(that).addClass('expand');
                    }, 500);
                } else {
                    jQuery(that).removeClass('sticky');
                    jQuery(that).removeClass('post_sticky');
                    jQuery(that).removeClass('expand');
                }
            });
        });
    }

    // tabs tarifs
    if (jQuery('.tarifs').length) {
        setTimeout(function() {
            var that = jQuery('.tarifs nav div').first();
            var offset = jQuery(that).offset();
            var width = jQuery(that).width();

            jQuery('.tarifs .hover').css({
                width: width
            });
            jQuery('.tarifs .hover').offset({ left: offset.left });
            jQuery('.tarifs .hover').addClass('active');

            if (responsive <= 480) {
                var that = jQuery('.tarifs nav div').first();
                var offset = jQuery(that).offset();
                var height = jQuery(that).height();

                jQuery('.tarifs .hover').css({
                    height: height + 30
                });
                jQuery('.tarifs .hover').offset({ top: offset.top });
                jQuery('.tarifs .hover').addClass('active');
            }
        }, 300);



        jQuery('.tarifs nav div').click(function(event) {
            var that = jQuery(this);
            jQuery('.tarifs nav div').removeClass('active');
            jQuery(that).addClass('active');
            var onglet = jQuery(that).attr('onglet');
            jQuery('.tarifs .nav-content div').removeClass('active');
            jQuery('.tarifs .nav-content div[onglet=' + onglet + ']').addClass('active');

            var offset = jQuery(that).offset();
            var width = jQuery(that).width();

            jQuery('.tarifs .hover').css({
                width: width
            });
            jQuery('.tarifs .hover').offset({ left: offset.left });

            if (responsive <= 480) {
                var that = jQuery(this);
                jQuery('.tarifs nav div').removeClass('active');
                jQuery(that).addClass('active');
                var onglet = jQuery(that).attr('onglet');
                jQuery('.tarifs .nav-content div').removeClass('active');
                jQuery('.tarifs .nav-content div[onglet=' + onglet + ']').addClass('active');

                var offset = jQuery(that).offset();
                var height = jQuery(that).height();

                jQuery('.tarifs .hover').css({
                    height: height + 30
                });
                jQuery('.tarifs .hover').offset({ top: offset.top });
            }

        });

        a = 0;
        jQuery('.tarifs .nav-content div').each(function(index, el) {
            var h = jQuery(this).height();
            if (h > a) {
                a = h;
            }
        });

        jQuery('.tarifs .nav-content').height(a);
    }

    // FILTRES
    if (jQuery('.search-carousel').length) {
        setTimeout(function() {

            jQuery('.owl-carousel').each(function(index, el) {
                var that = jQuery(this);
                jQuery(that).append('<div class="search-isotope-container"></div>');

                var wait = jQuery(that).find('.search-isotope-container');
                jQuery(that).find('.block').each(function(index, el) {
                    jQuery(this).clone().appendTo(jQuery(wait));
                });
                isotope_start(wait);
            });
        }, 1000);
    }

    if (jQuery('.search-isotope').length) {
        setTimeout(function() {
            var wait = jQuery('.search-isotope-container');
            isotope_start(wait);
        }, 1000);
    }

    function isotope_start(wait) {
        jQuery('main').addClass('no-overflow');
        $('.filter-button-group option[value="*"]').prop('selected', true);
        var filters = {};
        var selected;
        var rh = jQuery(wait).height();
        var $container = $(wait).isotope({
            itemSelector: '.block',
            visibleStyle: { transform: 'translateY(0)', opacity: 1 },
            hiddenStyle: { transform: 'translateY(100px)', opacity: 0 }
        });

        var isotp = $container.data('isotope');

        var nb = 12;
        var textdata = jQuery(wait).find('.content').text();

        if (pathname != /balades/) {
            $container.infiniteScroll({
                path: function() {
                    var i = this.pageIndex + 1;
                    return origin + '/ajax/pagination' + pathname + nb + '/' + i;
                },
                responseType: 'text',
                outlayer: isotp,
                status: '.page-load-status',
                history: false
            });

            $container.on('load.infiniteScroll', function(event, response) {
                var data = JSON.parse(response);
                var itemsHTML = data.map(getItemHTML).join('');
                var $items = $(itemsHTML)

                $items.find('.lazy').each((i, el) => {
                    el = $(el)

                    const img = el.attr('data-original')?.replace(/\s/g, '%20');
                    el.css('background-image', `url(${img})`)
                })

                $items.imagesLoaded(function() {
                    $container.infiniteScroll('appendItems', $items)
                        .isotope('appended', $items);

                    //Gestion des hauteurs en infinite Scroll (souci de top)
                    var o_h = 0;
                    jQuery('.block .content h5').each(function(index, el) {
                        var a_h = jQuery(this).height();
                        if (a_h > o_h) {
                            o_h = a_h;
                        }
                    });

                    jQuery('.block .content').height(o_h + 50);
                    //Gestion des hauteurs en infinite Scroll
                });
                textdata = jQuery(wait).find('.content').text();
                rh = jQuery(wait).height();
            });

            $container.infiniteScroll('loadNextPage');
        }

        var itemTemplateSrc = $('#template').html();

        function getItemHTML(photo) {
            return microTemplate(itemTemplateSrc, photo);
        }

        function microTemplate(src, data) {
            return src.replace(/\-\-([\w\-_\.]+)\-\-/gi, function(match, key) {
                var value = data;
                key.split('.').forEach(function(part) {
                    value = value[part];
                });
                return value;
            });
        }

        // get group key
        $(document).on("change", ".filter-button-group", function() {
            var child = $(this).children();
            $(child).each(function() {
                if (this.selected) {
                    selected = $(this).attr('data-filter');
                }
            });

            var $this = $(this);
            var $buttonGroup = $this;
            var filterGroup = $buttonGroup.attr('data-filter-group');
            filters[filterGroup] = selected;
            var filterValue = concatValues(filters);
            $container.isotope({ filter: filterValue });
            jQuery(wait).css('min-height', rh);
            if (filterValue == '*' || filterValue == ' ') {
                jQuery('.owl-carousel').removeClass('filtre-content');
                var oh = jQuery(wait).parent().find('.owl-stage-outer').height();
                jQuery(wait).parent().parent().css('height', oh + 50);
            } else {
                jQuery('.owl-carousel').addClass('filtre-content');
                jQuery(wait).parent().parent().css('height', rh);
            }

        });

        /* PERMET DE CHERCHER UN ELEMENT */
        $container.isotope('option', {
            getSortData: {
                name: function($elem) {
                    return textdata;
                }
            }
        });
        // update sortData on all items
        $container.isotope('updateSortData');

        function concatValues(obj) {
            var value = '';
            for (var prop in obj) {
                value += obj[prop];
            }
            return value;
        }

        function searchFilter() {
            qsRegex = new RegExp($quicksearch.val(), 'gi');
            $container.isotope('reloadItems');
            $container.isotope({
                filter: function() {
                    return qsRegex ? $(this).text().match(qsRegex) : true;
                }
            });
            jQuery(wait).css('min-height', rh);
            if ($quicksearch.val() == '') {
                var oh = jQuery(wait).height();
                jQuery('.owl-carousel').removeClass('filtre-content');
                jQuery('.owl-carousel').removeClass('filtre-content');

            } else {
                var ih = jQuery(wait).height();
                jQuery('.owl-carousel').addClass('filtre-content');
            }
        }

        var $quicksearch = $('.quicksearch').keyup(debounce(searchFilter));
        $('.quicksearch').keyup(function() {
            $container.isotope({ filter: '*' });
            $('.button-group option[value="*"]').prop('selected', true);
        });

        function debounce(fn, threshold) {
            var timeout;
            return function debounced() {
                if (timeout) {
                    clearTimeout(timeout);
                }

                function delayed() {
                    fn();
                    timeout = null;
                }
                setTimeout(delayed, threshold || 100);
            }
        }
    }

    //ANIMATIONS
    function hover(conteneur, el, ajust_left, ajust_top, type) {
        var hover = jQuery(conteneur).find('.hover');
        jQuery(hover).each(function(index, sub_el) {
            setTimeout(function() {
                hover = sub_el;
                if (jQuery(hover).attr('cible')) {
                    var cible = jQuery(hover).attr('cible');
                    cible = jQuery(el).find(cible);
                    var offset = jQuery(cible).offset();
                    var width = jQuery(cible).width();
                    var height = jQuery(cible).height();
                } else {
                    var offset = jQuery(el).offset();
                    var width = jQuery(el).width();
                    var height = jQuery(el).height();
                }

                var top = offset.top;

                if (type == 'line') {
                    top = offset.top + height;
                    height = '2px';
                }

                jQuery(hover).css({
                    width: width,
                    height: height
                });

                jQuery(hover).offset({ left: offset.left - ajust_left, top: top - ajust_top });
                jQuery(hover).addClass('active');
            }, 200 * index);
        });
    }

    //grid
    jQuery('.grid .block').mouseenter(function(event) {
        var that = jQuery(this);
        if (jQuery(that).find('a').length) {
            jQuery('.grid .block').removeClass('active');
            jQuery(that).addClass('active');
        }

    });

    // MENU
    jQuery('#header .menu li').mouseenter(function(event) {
        hover('#header', this, '8', '5', 'full');
        jQuery('#header .menu li').removeClass('active');
        jQuery(this).addClass('active');
    });

    // FOOTER
    jQuery("#footer ul li a").mouseenter(function(event) {
        hover('#footer', this, '5', '3', 'full');
    });

    //events
    jQuery('body').on('mouseenter', '.retro .block', function(event) {
        event.preventDefault();
        link(this);
    });

    //BOUTON
    jQuery('.button').mouseenter(function(event) {
        if (!jQuery(this).hasClass('disable')) {
            var that = jQuery(this);
            var x = event.offsetX;
            var y = event.offsetY;
            if (!jQuery(that).find('.bouton-effet').length) {

                var rand = random(2, 5);
                for (var i = 0; i < rand; i++) {
                    jQuery(that).append('<div class="bouton-effet" style="top:' + y + 'px;left:' + x + 'px"></div>');
                }

                setTimeout(function() {
                    jQuery(that).find('.bouton-effet').each(function(index, el) {
                        var anim_elem = jQuery(this);
                        setTimeout(function() {
                            jQuery(anim_elem).addClass('active');
                            setTimeout(function() {
                                jQuery(anim_elem).addClass('none');
                            }, 800);
                            setTimeout(function() {
                                jQuery(anim_elem).remove();
                            }, 2000);
                        }, index * 150);
                    });
                }, 50);
            }
        }
    });

    //LINK
    function link(el) {
        var that = jQuery(el);
        jQuery(that).addClass('active');

        var rand = random(2, 4);
        var w = random(40, 100);

        for (var i = 0; i < rand; i++) {
            jQuery(that).append('<div class="link-effet" style="width:' + w + 'px;"></div>');
        }

        jQuery(that).find('.link-effet').each(function(index, el) {
            setTimeout(function() {
                setTimeout(function() {
                    jQuery(el).addClass('active');
                }, 50);

                setTimeout(function() {
                    jQuery(el).addClass('none');
                }, 250);

                setTimeout(function() {
                    jQuery(el).remove();
                }, 600);
            }, 150 * index);
        });
    }

    jQuery('body').on('mouseenter', '.link, .block .content a', function(event) {
        event.preventDefault();
        link(this);
    });

    //COVERTRI
    if (responsive > 1007) {
        jQuery('.covertri .content div').mouseenter(function(event) {
            var offset = jQuery(this).offset();
            var width = jQuery(this).width();
            jQuery('.covertri .content div').removeClass('select');
            jQuery(this).addClass('select');
            jQuery('.covertri .hover').css({
                width: width + 110
            });
            jQuery('.covertri .hover').offset({ left: offset.left });
            jQuery('.covertri .hover').addClass('active');
        });
    } else {
        jQuery('.covertri .content div').mouseenter(function(event) {
            var offset = jQuery(this).offset();
            var height = jQuery(this).height();
            var width = jQuery(this).width();
            jQuery('.covertri .content div').removeClass('select');
            jQuery(this).addClass('select');
            jQuery('.covertri .hover').css({
                width: width + 1300,
                height: height + 40
            });
            jQuery('.covertri .hover').offset({ top: offset.top });
            jQuery('.covertri .hover').addClass('active');
        });
    }


    //animation icon au scroll
    if (jQuery('.block-icons .icon svg').length) {
        var block_icons = $('.block-icons').offset();
        jQuery(document).scroll(function() {
            var scroll = jQuery('html,body').scrollTop();
            if (scroll >= block_icons.top - 780) {
                jQuery('.block-icons .icon svg').addClass('svg');
            }
        })
    }

    //COVER HOME
    if (jQuery('.anim .bg').length) {
        var scene = document.getElementById('scene_home');
        var parallaxInstance = new Parallax(scene, {
            relativeInput: true,
            clipRelativeInput: true,
            scalarY: 5
        });

        var scene = document.getElementById('scene_home_route');
        var parallaxInstance = new Parallax(scene, {
            relativeInput: true,
            clipRelativeInput: true,
            scalarY: 5
        });

        var scene = document.getElementById('scene_home_foret');
        var parallaxInstance = new Parallax(scene, {
            relativeInput: true,
            clipRelativeInput: true,
            scalarY: 5
        });
    }

    //TRIPICS
    if (jQuery('.tripic').length) {
        if (responsive > 768) {
            jQuery('.tripic .ligne').each(function(index, el) {
                var parallaxInstance = new Parallax(el, {
                    relativeInput: true,
                    clipRelativeInput: true,
                    scalarY: 5,
                    scalarX: 5,
                    frictionY: 0.5
                });
            });
        }
    }

    function drawingicons() {
        if (jQuery('.block-icons .icon svg').length) {
            jQuery('.block-icons .icon svg').addClass('svg');
        }
    }

    //SCROLL
    if (responsive > 768) {
        ScrollReveal().reveal('.carte-conteneur .carte', { delay: 300, reset: false, distance: '500px', duration: 750, easing: 'cubic-bezier(0.18, 0.89, 0.32, 1.1)', interval: 20, opacity: 0, origin: 'right', scale: 1, rotate: { x: 30, y: 30, z: 0 }, afterReveal: lazy });
        ScrollReveal().reveal('.boutique img', { delay: 300, reset: false, distance: '200px', duration: 500, easing: 'linear', interval: 50, opacity: 0, origin: 'right', scale: 1, rotate: { x: 0, y: 50, z: 0 }, afterReveal: lazy });
        ScrollReveal().reveal('.actus .side', { delay: 300, reset: false, distance: '70px', duration: 400, easing: 'linear', interval: 50, opacity: 0, origin: 'top', scale: 1, rotate: { x: 20, y: 20, z: 0 }, afterReveal: lazy });
        ScrollReveal().reveal('.texte-image .image', { delay: 300, reset: false, distance: '70px', duration: 400, easing: 'linear', interval: 50, opacity: 0, origin: 'bottom', scale: 1, rotate: { x: 20, y: 20, z: 0 }, afterReveal: lazy });
        ScrollReveal().reveal('.gallerie .block', { delay: 300, reset: false, distance: '70px', duration: 400, easing: 'linear', interval: 10, opacity: 0, origin: 'left', scale: 1, rotate: { x: 0, y: 0, z: 0 }, afterReveal: lazy });
        ScrollReveal().reveal('.inscription.assurance .resume-text', { delay: 300, reset: false, distance: '70px', duration: 400, easing: 'linear', interval: 10, opacity: 0, origin: 'top', scale: 1, rotate: { x: 0, y: 0, z: 0 }, afterReveal: lazy });
        ScrollReveal().reveal('.formule .tab-formules .far', { delay: 300, reset: false, distance: '40px', duration: 600, easing: 'linear', interval: 10, opacity: 0, origin: 'top', scale: 1, rotate: { x: 0, y: 0, z: 0 }, afterReveal: lazy });
        ScrollReveal().reveal('.chiffres.axe .row .row .col-9', { delay: 300, reset: false, distance: '300px', duration: 600, easing: 'linear', interval: 10, opacity: 0, origin: 'right', scale: 1, rotate: { x: 0, y: 0, z: 0 }, afterReveal: lazy });
        ScrollReveal().reveal('.chiffres.axe .row .row .col-2 ', { delay: 300, reset: false, distance: '100px', duration: 400, easing: 'linear', interval: 10, opacity: 0, origin: 'top', scale: 1, rotate: { x: 0, y: 0, z: 0 }, afterReveal: lazy });
        ScrollReveal().reveal('.chiffres .content-text p', { delay: 300, reset: false, distance: '200px', duration: 600, easing: 'linear', interval: 10, opacity: 0, origin: 'right', scale: 1, rotate: { x: 0, y: 0, z: 0 }, afterReveal: lazy });
        ScrollReveal().reveal('.chiffres .axa p', { delay: 300, reset: false, distance: '200px', duration: 600, easing: 'linear', interval: 10, opacity: 0, origin: 'right', scale: 1, rotate: { x: 0, y: 0, z: 0 }, afterReveal: lazy });
        ScrollReveal().reveal('.block-icons .icon', { delay: 300, reset: false, duration: 600, easing: 'linear', interval: 10, opacity: 0, scale: 1, rotate: { x: 0, y: 0, z: 0 }, afterReveal: drawingicons });
    }

    //Fonction ouverture / fermerture des popins Roadbook + ajout avis

    jQuery(".button-popin").click(function(event) {
        // if (jQuery('.search-isotope').length) {
        var popin_conteneur = jQuery(this).closest('section');
        jQuery(popin_conteneur).addClass('popin-active');
        jQuery('.menu , #header, .hamburger').removeClass("is-active");
        jQuery('#header').css("z-index", "10");
        // }
    });

    if (!jQuery('.register').length) {
        jQuery(".popin").click(function(event) {
            if (jQuery(event.target).hasClass('popin')) {
                $("section").removeClass('popin-active');
                jQuery('#header').css("z-index", "1030");
            }
        });

        jQuery(".popin-close").click(function(event) {
            $("section").removeClass('popin-active');
            jQuery('#header').css("z-index", "1030");
        });
    }


    // histoire timeline
    var responsive = jQuery('body').width();
    var heighstep = 0;
    if (responsive > 768) {
        jQuery('.chrono .date').click(function(event) {
            var parent = jQuery(this).parent();
            var that = jQuery(this);

            jQuery(parent).find('.date').each(function(index, el) {
                if (jQuery(this)[0] == that[0]) {
                    index++;
                    jQuery('.chrono .row .step').removeClass('active');
                    jQuery('.chrono .row .step:nth-child(' + index + ')').addClass('active');
                    var step = jQuery('.chrono .row .step:nth-child(' + index + ').active');
                    var heightstep = jQuery(step).find('.data-content .content-text').height();
                    jQuery('.chrono .row .step.active').height(heightstep);
                    jQuery('.chrono').height(heightstep + 50);
                    jQuery('.chrono .date').removeClass('active');
                    jQuery('.chrono .date:nth-child(' + index + ')').addClass('active');
                    var step = jQuery('.chrono .date:nth-child(' + index + ') .active');
                }
            });
        });
    } else {
        var that = jQuery('.chrono .row .step').addClass('active');
        owl('.chrono .row .carroussel', true, 1, false, 0, false, true, false);
        var item_actif = jQuery('.chrono .owl-item.active .step .data-content');
        var element_parent_hgt = jQuery('.chrono .owl-item.active .step');

        var item_actif_hgt = item_actif.height();
        element_parent_hgt.height(item_actif_hgt + 85);
        jQuery('.chrono .owl-stage').height(item_actif_hgt + 100);
        jQuery('.chrono').height(item_actif_hgt + 200);
        // focus, center, items, loop, margin, width, nav, auto
    }

    //function pour le compteur de la page association
    //Maelys
    var a = 0;
    $(window).scroll(function() {
        if (jQuery('#counter').length) {
            var oTop = $('#counter').offset().top - window.innerHeight;
            if (a == 0 && $(window).scrollTop() > oTop) {
                $('.count').each(function() {
                    var $this = $(this),
                        countTo = $this.attr('data-count');
                    $({
                        countNum: $this.text()
                    }).animate({ countNum: countTo }, {
                        delay: 400,
                        duration: 3500,
                        easing: 'swing',
                        step: function() {
                            $this.text(Math.floor(this.countNum));
                        },
                        complete: function() {
                            $this.text(this.countNum);
                        }
                    });
                });
                a = 1;
            }
        }
    });

    //affichage des description + function de hover
    jQuery(".comp-formule .infocomp i").click(function(event) {
        var comp = $(this).closest('.comp-formule');
        var explication = comp.find('.descriptioncomp p');
        jQuery(explication).toggleClass('active');
        var heightexplication = jQuery(explication).outerHeight();
        var compIndex = $(comp).index();
        var heightcomp = jQuery(comp).outerHeight();
        var composantIndex = 0;
        var composants = document.getElementsByClassName('composant');
        var composants = Array.prototype.filter.call(composants, function(composant) {
            var composantIndex = ($(composant).index());
            if (compIndex === composantIndex) {
                $(composant).css('height', heightcomp);
                $(composant).animate({
                    height: heightcomp
                }, 50);


            }
        });
    });

    $(".comp").hover(
        function() {
            var compIndex = $(this).index();
            var composants = document.getElementsByClassName('comp');
            var composants = Array.prototype.filter.call(composants, function(composant) {
                var composantIndex = ($(composant).index());
                if (compIndex === composantIndex) {
                    $(composant).css('background', '#e3f3fd');
                }
            });
        },
        function() {
            var compIndex = $(this).index();
            var composants = document.getElementsByClassName('comp');
            var composants = Array.prototype.filter.call(composants, function(composant) {
                var composantIndex = ($(composant).index());
                if (compIndex === composantIndex) {
                    $(composant).css('background', 'none');
                }
            });
        }
    );

    //POPIN
    jQuery('.popin .button').click(function(event) {
        var that = jQuery(this);
        var content = jQuery(that).closest('.popin-content');
        var form = jQuery(content).find('form');
        var validator = $(form).validate();
        var token = true;
        data = {};

        jQuery(form).each(function(index, row_el) {
            jQuery(row_el).find('input,textarea').not(that).each(function(index, el) {
                validate(el, validator);
            });
        });

        if (token == true) {
            var rgpd_val = '';
            var newsletter_val = '';
            if (jQuery(content).hasClass('commentaire')) {
                var email = jQuery(content).find('.email input').val();
                var commentaire = jQuery(content).find('.comm').val();
                var nom = jQuery(content).find('.names input').val();
                var newsletter = jQuery(content).find('#popin-newletter');
                var rgpd = jQuery(content).find('#popin-rgpd');
                rgpd_val = jQuery(rgpd).hasClass('checked-active');
                newsletter_val = jQuery(newsletter).hasClass('checked-active');
                var type = jQuery(content).attr('type');
                var url = "/ajax/avis";
                var data = '{ "type": "' + type + '", "email": "' + email + '", "commentaire": "' + commentaire + '", "nom": "' + nom + '", "newsletter": "' + newsletter_val + '", "rgpd":"' + rgpd_val + '"}';
            } else if (jQuery(content).hasClass('mail')) {
                var email = jQuery(content).find('.email').val();
                var newsletter = jQuery(content).find('#popin-newletter');
                var rgpd = jQuery(content).find('#popin-rgpd');
                rgpd_val = jQuery(rgpd).hasClass('checked-active');
                newsletter_val = jQuery(newsletter).hasClass('checked-active');
                var id = jQuery(content).attr('id');
                var url = "/ajax/roadbook";
                var data = '{ "type": "roadbook", "email": "' + email + '", "newsletter": "' + newsletter_val + '", "rgpd":"' + rgpd_val + '", "id":"' + id + '"}';
            }   

            // console.log("#### POST-0: ")
            // console.log(origin)
            // console.log(url)

            $.ajax({
                    method: "POST",
                    url: origin + url,
                    data: data,
                    dataType: 'json',
                })
                .done(function(msg) {
                    // En fonction du message afficher la fenettre ou non
                    if (debug == 'true') {
                        console.log("Data Saved: " + JSON.stringify(msg), "Status: " + status);
                    }
                });
        }

        if (jQuery(content).hasClass('commentaire')) {
            var email = jQuery(content).find('.email input').val();
            var commentaire = jQuery(content).find('.comm').val();
            var nom = jQuery(content).find('.names input').val();
            var newsletter = jQuery(content).find('#popin-newletter').val();
            var rgpd = jQuery(content).find('#popin-rgpd').val();
            var type = jQuery(content).attr('type');
            var url = "/ajax/avis";
            var data = '{ "type": "' + type + '", "email": "' + email + '", "commentaire": "' + commentaire + '", "nom": "' + nom + '", "newsletter": "' + newsletter + '", "rgpd":"' + rgpd + '", id:"' + id + '"}';

        } else if (jQuery(content).hasClass('mail')) {
            var email = jQuery(content).find('.email').val();
            var newsletter = jQuery(content).find('#popin-newletter').val();
            var rgpd = jQuery(content).find('#popin-rgpd').val();
            var id = jQuery(content).attr('id');
            var url = "/ajax/roadbook";
            var data = '{ type: "roadbook", email: "' + email + '", newsletter: "' + newsletter + '", rgpd:"' + rgpd + '", id:"' + id + '"}';

        }

        // console.log("#### POST-1: ")
        // console.log(origin)
        // console.log(url)
        $.ajax({
                method: "POST",
                url: origin + url,
                data: data,
                dataType: 'json',
            })
            .done(function(msg) {
                if (debug == 'true') {
                    console.log("Data Saved: " + JSON.stringify(msg), "Status: " + status);
                }
            });
    });

    //function checked des input radio des formulaires.
    jQuery("input[type='radio']").click(function(event) {
        jQuery(this).toggleClass('checked-active');
    });

    jQuery("input[type='checkbox']").click(function(event) {
        jQuery(this).parent().toggleClass('checked-active');
        if (jQuery(this).parent().find('.light').length) {
            var that = jQuery(this).parent().find('.light')[0]
            setTimeout(function() {
                that.focus();
            }, 400);

        }
    });


    /*
     * Translated default messages for the jQuery validation plugin.
     * Locale: FR (French; français)
     */
    $.extend($.validator.messages, {
        required: "Ce champ est obligatoire.",
        remote: "Veuillez corriger ce champ.",
        email: "Veuillez fournir une adresse électronique valide.",
        url: "Veuillez fournir une adresse URL valide.",
        date: "Veuillez fournir une date valide.",
        dateISO: "Veuillez fournir une date valide (ISO).",
        number: "Veuillez fournir un numéro valide.",
        digits: "Veuillez fournir seulement des chiffres.",
        creditcard: "Veuillez fournir un numéro de carte de crédit valide.",
        equalTo: "Veuillez fournir encore la même valeur.",
        notEqualTo: "Veuillez fournir une valeur différente, les valeurs ne doivent pas être identiques.",
        extension: "Veuillez fournir une valeur avec une extension valide.",
        maxlength: $.validator.format("Veuillez fournir au plus {0} caractères."),
        minlength: $.validator.format("Veuillez fournir au moins {0} caractères."),
        rangelength: $.validator.format("Veuillez fournir une valeur qui contient entre {0} et {1} caractères."),
        range: $.validator.format("Veuillez fournir une valeur entre {0} et {1}."),
        max: $.validator.format("Veuillez fournir une valeur inférieure ou égale à {0}."),
        min: $.validator.format("Veuillez fournir une valeur supérieure ou égale à {0}."),
        step: $.validator.format("Veuillez fournir une valeur multiple de {0}."),
        maxWords: $.validator.format("Veuillez fournir au plus {0} mots."),
        minWords: $.validator.format("Veuillez fournir au moins {0} mots."),
        rangeWords: $.validator.format("Veuillez fournir entre {0} et {1} mots."),
        letterswithbasicpunc: "Veuillez fournir seulement des lettres et des signes de ponctuation.",
        alphanumeric: "Veuillez fournir seulement des lettres, nombres, espaces et soulignages.",
        lettersonly: "Veuillez fournir seulement des lettres.",
        nowhitespace: "Veuillez ne pas inscrire d'espaces blancs.",
        ziprange: "Veuillez fournir un code postal entre 902xx-xxxx et 905-xx-xxxx.",
        integer: "Veuillez fournir un nombre non décimal qui est positif ou négatif.",
        vinUS: "Veuillez fournir un numéro d'identification du véhicule (VIN).",
        dateITA: "Veuillez fournir une date valide.",
        time: "Veuillez fournir une heure valide entre 00:00 et 23:59.",
        phoneUS: "Veuillez fournir un numéro de téléphone valide.",
        phoneUK: "Veuillez fournir un numéro de téléphone valide.",
        mobileUK: "Veuillez fournir un numéro de téléphone mobile valide.",
        strippedminlength: $.validator.format("Veuillez fournir au moins {0} caractères."),
        email2: "Veuillez fournir une adresse électronique valide.",
        url2: "Veuillez fournir une adresse URL valide.",
        creditcardtypes: "Veuillez fournir un numéro de carte de crédit valide.",
        ipv4: "Veuillez fournir une adresse IP v4 valide.",
        ipv6: "Veuillez fournir une adresse IP v6 valide.",
        require_from_group: $.validator.format("Veuillez fournir au moins {0} de ces champs."),
        nifES: "Veuillez fournir un numéro NIF valide.",
        nieES: "Veuillez fournir un numéro NIE valide.",
        cifES: "Veuillez fournir un numéro CIF valide.",
        postalCodeCA: "Veuillez fournir un code postal valide."
    });

    //FORM
    var token = true;
    var data_full = '';
    var data = {};

    function validate(el, validator) {
        var validate = validator.element(el);
        jQuery(el).removeClass('is-invalid');
        jQuery(el).removeClass('is-valid');

        var value = jQuery(el).val();
        var label = jQuery(el).attr('name');
        var required = jQuery(el).attr('required');
        var max = jQuery(el).attr('maxlength');

        if (jQuery(el).attr('type') == 'radio') {
            var parent = jQuery(el).closest('.input.radio');
            label = jQuery(parent).attr('name');
            jalon = false;
            var required = false;

            jQuery(parent).find('input').each(function(index, elem) {
                if (jQuery(elem).prop('checked') == true) {
                    value = jQuery(elem).val();
                    jalon = true;
                }
            });

            if (jalon == true) {

                jQuery(parent).addClass('is-valid');
                jQuery(parent).removeClass('is-invalid');

                token = true;

                var errordiv = jQuery(parent).find('.error');

                if (jQuery(errordiv).length) {
                    jQuery(errordiv).remove();
                }

            } else {
                value = 'false';
                if (jQuery(parent).attr('required')) {
                    jQuery(parent).addClass('is-invalid');
                    var id = jQuery(parent).attr('id');
                    var name = jQuery(parent).attr('name');
                    var error = jQuery(parent).find('#' + id + '-error');
                    if (jQuery(error).length == 0) {
                        jQuery(parent).prepend('<label id="' + id + '-error" class="error" for="' + label + '">Ce champ est obligatoire.!.</label>');
                    }
                    token = false;
                } else {
                    jQuery(parent).addClass('is-valid');
                    token = true;
                }
            }


        } else {
            if (jQuery(el).attr('type') == 'checkbox') {
                if (jQuery(el).attr('required')) {
                    if (jQuery(el).is(":checked") == true) {
                        jQuery(el).addClass('is-valid');
                        value = 'true';
                    } else {
                        jQuery(el).addClass('is-invalid');
                        token = false;
                        value = 'false';
                    }
                } else {
                    if (jQuery(el).is(":checked") == true) {
                        value = 'true';
                    } else {
                        value = 'false';
                    }
                }
            } else {
                if (jQuery(el)[0].tagName == 'SELECT') {
                    if (jQuery(el).attr('required')) {
                        var option = jQuery(el).val();
                        if (option.length) {
                            /*VALIDE*/
                            jQuery(el).addClass('is-valid');
                        } else {
                            /*INVALIDE*/
                            jQuery(el).addClass('is-invalid');
                            token = false;
                        }
                    }
                } else {
                    if (validate == true) {
                        /*VALIDE*/
                        jQuery(el).addClass('is-valid');
                    } else {
                        /*INVALIDE*/
                        jQuery(el).addClass('is-invalid');
                        token = false;
                    }
                }
            }
        }

        if (token == true) {
            if (!value.trim()) {
                value = null;
            }

            var key = label;
            var value = value;

            if (jQuery(el).attr('data-option')) {
                if (typeof data.options === 'undefined') {
                    data.options = {};
                }

                data.options[key] = value;
            } else if (jQuery(el).attr('data-type') === 'collection') {
                if (typeof data[key] === 'undefined') {
                    data[key] = [];
                }

                if (value && value.length) {
                    data[key].push(value);
                }
            } else {
                data[key] = value;
            }
        }
    }

    //INSCRIPTION
    if (jQuery('.register').length) {
        var url = jQuery('.register').attr('event');

        var that = jQuery('.register');
        var form = jQuery(that).find('form');

        var step_full = jQuery(form).find('.step').last();
        var step = jQuery(step_full).attr('step');

        height_step();
        get_cookie();
        checkbox();

        setTimeout(function() {
            var titre_step = jQuery(step_full).attr('title');
            titre(titre_step);
            radiogroup();

            if (step == '6') {
                popin_reglement();
                button_name(step);
            }
        }, 150);

        function height_step() {
            var height_bloc = jQuery(form).find('.step').last().height();
            jQuery('.register form .content_step').height(height_bloc);
        }

        function check_step() {
            get_cookie();
            var step = jQuery('.register form .step');
            if (step.length) {
                var count = step.length;
                if (count == 1) {
                    jQuery('.register form .prev').removeClass('active');
                } else {
                    jQuery('.register form .prev').addClass('active');
                }
            }
        }

        function button_name(step) {
            if (step == '6') {
                jQuery('.register .block-button .next_step').text('Payer et confirmer mon inscription');
            } else {
                jQuery('.register .block-button .next_step').text('Prochaine étape');
            }
        }

        //progress bar input range : color
        if (jQuery('input.slider').length) {
            jQuery('input.slider').on('input', function() {
                jQuery(this).css('background', 'linear-gradient(to right, red 0%, red ' + this.value * 10 + '%, #d3d3d3 ' + this.value * 10 + '%, #d3d3d3 100%)');
            });
        }

        //Gestion de la nav dynamiquement.
        var nbEtapes = jQuery(".register nav").attr("nbEtapes");
        for (var i = 0; i < nbEtapes; i++) {
            jQuery('.register nav').append("<div></div>");
            jQuery('.register nav hr').css('width', 40 * i);
        }
        jQuery('.register nav div:first-of-type').addClass('active'); // pour commencer à changer la classe active. 
        // jQuery('.register nav').append("<div></div>");

        //changement état bouton popin réglement
        jQuery(".register").on("click", "input#valid_reglement", function() {
            if (jQuery(this).is(':checked')) {
                jQuery(".register .button.popin-close-valid").removeClass('disable');
            } else {
                jQuery(".register .button.popin-close-valid").addClass('disable');
            }
        });

        //VAlIDATION POPIN
        jQuery(".register").on("click", ".popin-close-valid", function() {
            if (jQuery("input#valid_reglement").is(":checked")) {
                jQuery("section").removeClass('popin-active');
                jQuery('#header').css("z-index", "1030");
            }
        });

        // VALIDATION ETAPE
        jQuery(".register form").on("click", ".button.next_step", function() {
            verif_js(url);
        });

        jQuery(".register form").on("click", ".prev", function() {
            /* BLOCK PRECEDENT */
            var steplast = jQuery(form).find('.step').last();
            setTimeout(function() {
                jQuery(steplast).removeClass('active');

                setTimeout(function() {
                    var stepactive = jQuery(steplast).prev('.step');
                    jQuery(stepactive).addClass('active');
                    jQuery(steplast).remove();
                    height_step();
                    jQuery('.register .next_step').show();
                    button_name(stepactive);
                    var titre_step = jQuery(stepactive).attr('title');
                    titre(titre_step);

                    //#ICI copier coller et changer le code pour la nav
                }, 300);
            }, 200);

            check_step();
        });

        function verif_js(url) {
            token = true;
            data_full = '';
            var message = false;
            data = {};

            var validator = jQuery(form).validate();


            jQuery(form).each(function(index, row_el) {
                jQuery(row_el).find('input,textarea,select').each(function(index, el) {
                    validate(el, validator);
                });
            });


            //SI PAS D'ERREUR JS
            //pour les tests
            // token = true;

            if (token == true) {
                var step = jQuery(form).find('.step').last().attr('step');
                step = parseInt(step);
                verif_back(step, url, data);
            } else {
                message = 'Erreur dans le formulaire';
                if (debug == 'true') {
                    console.log('Erreur JS');
                }
                wizz();
            }

            msg_error(message);

        }

        function message_back(erreur_form) {
            if (debug == 'true') {
                console.log('---- VERIF ERREUR BACK ------');
                console.log(erreur_form);
                console.log('---- FIN VERIF ERREUR BACK ------');
            }
            $.each(erreur_form, function(key, val) {
                var step_erreur = jQuery('.register form .content_step .step[step="' + key + '"]');
                $.each(erreur_form[key], function(key, val) {
                    var conteneur = jQuery(step_erreur[0]).find('*[name="' + key + '"').parent();
                    if (jQuery(conteneur).find('#' + key + '-error').length) {
                        jQuery(conteneur).find('#' + key + '-error').text(val);
                        jQuery(conteneur).find('#' + key + '-error').css('display', 'block');
                    } else {
                        jQuery(conteneur).append('<label id="' + key + '-error" class="error" for="' + key + '">' + val + '</label>');
                    }
                });
            });
        }

        function msg_error(message) {
            if (message != false) {
                var step_active = jQuery('.register form .content_step').find('.step.active');
                if (jQuery(step_active).find('.error-global').length) {
                    jQuery(step_active).find('.error-global h4').html(message);
                } else {
                    jQuery(step_active).append('<div class="error error-global"><h4>' + message + '</h4></div>');
                }
            } else {
                if (jQuery(step_active).find('.error-global').length) {
                    jQuery(step_active).find('.error-global h4').html('');
                }
            }
        }

        //changement text étape 6
        jQuery(".register h2").text(jQuery(".step").attr("title"));
        if (jQuery(".step.active").attr("title") == "paiement") {
            jQuery(" .register .button.next_step").text('Valider mon paiement');
        }

        function popin_reglement() {
            var popin_conteneur = jQuery("section.register");
            // #ICI ajout call ajax popin 
            /*            var chemin = 'trouver la bonne url pour call le fichier du popin';
                        $.get(chemin, function(retourne_le_code_du_bloc_call) {
                            jQuery('.toncontainer').append(retourne_le_code_du_bloc_call);
                        });*/

            //#ici attendre 300ms

            jQuery(popin_conteneur).addClass('popin-active');
            jQuery('.menu , #header, .hamburger').removeClass("is-active");
            jQuery('#header').css("z-index", "10");
            //Scrollbar personnalisé
            var scroll = jQuery(".reglement").mCustomScrollbar({
                axis: "y"
            });

            setTimeout(function() {
                $(scroll).mCustomScrollbar("update");
            }, 1000);
        }

        function change_step(step, url) {
            if (debug == 'true') {
                console.log('--------- CHANGE STEP -----------');
            }
            step = step + 1;
            var chemin = origin + "/ajax/front/etapes-html/" + step + "/" + url;

            $.get(chemin, function(bloc) {
                jQuery('.register form .content_step').find('.step').removeClass('active');
                if (debug == 'true') {
                    console.log(bloc);
                    console.log('vide : ' + jQuery(bloc).attr('vide'));
                    console.log('step : ' + jQuery(bloc).attr('step'));
                }

                var vide = 'false';
                if (jQuery(bloc).attr('vide').length) {
                    vide = jQuery(bloc).attr('vide');
                }

                if (vide == 'false') {
                    jQuery('.register form .content_step').append(bloc);
                    setTimeout(function() {
                        height_step();
                        if (step == '6') {
                            popin_reglement();
                            calcul_prix();
                            console.log(responsive);
                            if (responsive < 768) {
                                jQuery('.register form .content_step').css('height', '250px');
                            } else {
                                jQuery('.register form .content_step').css('height', '104px');
                            }


                            jQuery('.register .block-button').hide();
                            jQuery('.register form .icon.acc.minusone').hide();
                        }

                        button_name(step);

                        setTimeout(function() {
                            var topregister = jQuery('.register').offset();
                            jQuery(document).scrollTop(topregister.top - 50);
                            var titre_step = jQuery(bloc).attr('title');
                            titre(titre_step);
                            jQuery('.register form .content_step .step').last().addClass('active');
                            jQuery('.register form .content_step .step').find('.error-global').remove();
                            check_step();
                            var nav_step = step + 1;
                            jQuery('.register nav div').removeClass('active');
                            jQuery('.register nav div:nth-child(' + nav_step + ')').addClass('active');
                            radiogroup();
                        }, 100);
                    }, 100);
                } else {
                    if (debug == 'true') {
                        console.log('condition vide step = ' + step);
                    }
                    if (step == '6') {
                        //afficher le popin directement si étape de paiement vide
                        if (debug == 'true') {
                            console.log('etape vide 6');
                        }
                        popin_reglement();
                        button_name(step);
                        // TODO
                    } else {
                        if (debug == 'true') {
                            console.log('etape ' + step + ' vide, recall function change step');
                        }
                        // si étape vide relancer la fonction avec étape suivante
                        change_step(step, url);
                    }
                }
            });
            if (debug == 'true') {
                console.log('--------- END CHANGE STEP -----------');
            }
        }

        function verif_back(step, url, data) {
            if (debug == 'true') {
                console.log('verif back = ' + step);
                console.log('--------- DATA BACK SEND -----------');
                console.log(data);
                console.log('--------- END DATA BACK -----------');
            }

            // console.log("#### POST-2: "+origin + "/ajax/validate-field/" + url)

            $.ajax({
                    method: "POST",
                    url: origin + "/ajax/validate-field/" + url,
                    data: {
                        step: parseInt(step),
                        data: data
                    },
                    dataType: 'json',
                })
                .done(function(msg, status) {
                    // En fonction du message afficher la fenettre ou non
                    if (debug == 'true') {
                        console.log('--------- DATA BACK RECEIVE -----------');
                        console.log(msg);
                        console.log('--------- END DATA BACK RECEIVE -----------');
                    }
                    var valid = msg['success'];
                    var erreur = msg['valid'];
                    var message = false;

                    if (valid == false) {
                        message = 'Erreur lors du processus';
                        if (debug == 'true') {
                            console.log('Erreur processus BACK');
                            wizz();
                        }
                    } else {
                        if (erreur == true) {
                            if (debug == 'true') {
                                console.log("pas d'erreur");
                            }
                            /*SI PAS D ERREUR EN BACK*/
                            if (step == 6) {
                                if (debug == 'true') {
                                    console.log(step);
                                }
                                paiement(step, url, data);
                            } else {
                                if (debug == 'true') {
                                    console.log('call function change_step');
                                }

                                change_step(step, url);
                                set_cookie(data);
                            }

                        } else {
                            /* ERREUR BACK */
                            message = 'Erreur dans le formulaire';
                            message_back(msg['data']);
                            wizz();
                            if (debug == 'true') {
                                console.log('Erreur BACK');
                            }
                        }
                    }

                    msg_error(message);
                });
        }

        function wizz() {
            var scroll_anim = jQuery(document).scrollTop() - 100;
            $('html, body').animate({
                scrollTop: scroll_anim
            }, 500);
        }

        function get_cookie() {
            if (debug == 'true') {
                console.log('--------- GET COOKIE JS -----------');
            }

            var cookie = Cookies.get();
            if (debug == 'true') {
                console.log(cookie);
            }

            jQuery.each(cookie, function(key, value) {
                jQuery('.register form input,.register form select, .register form .input.radio').each(function(index, el) {
                    var label = jQuery(el).attr('name');
                    if (label == key) {
                        if (value != 'null') {
                            var type = jQuery(el).attr('type');
                            var tag = jQuery(el)[0].tagName;
                            if (jQuery(el).attr("class") == 'input radio') {
                                // RADIO 
                                jQuery(el).find('input').each(function(i, elem) {
                                    if (jQuery(elem).prop('value') == value) {
                                        jQuery(elem).prop("checked", true);
                                        jQuery(elem).parent('.content-card-radio').addClass('active');
                                    }
                                });
                            } else {
                                if (type == 'checkbox') {
                                    // CHECKBOK
                                    if (value == 'true') {
                                        jQuery(el).prop("checked", true);
                                    } else {
                                        jQuery(el).prop("checked", false);
                                    }
                                } else {
                                    if (tag == 'SELECT') {
                                        // SELECT
                                        jQuery(el).find('option').each(function(index, el) {
                                            var option_val = jQuery(el).val();
                                            if (option_val == value) {
                                                jQuery(el).prop('selected', true);
                                            }
                                        });
                                    } else {
                                        //   INPUT
                                        jQuery(el).val(value);
                                    }
                                }
                            }
                        }
                    }
                });
            });

            //#ici vérifier pour chaque étape que chaque champs est bien remplis si tout est 
            //remplis dans une étape alors call la function change_step(X) ou x = le numéro de l'étape

            setTimeout(function() {
                checkbox();
                if (jQuery(".register form .participant").length) {
                    participant();
                    add_field();
                }
            }, 100);

            if (debug == 'true') {
                console.log('--------- END GET COOKIE JS -----------');
            }
        }

        function set_cookie(data) {
            if (debug == 'true') {
                console.log('--------- SET COOKIE JS -----------');
                console.log(data);
            }

            jQuery.each(data, function(index, value) {
                Cookies.set(index, value);
            });

            if (debug == 'true') {
                console.log('--------- END SET COOKIE JS -----------');
            }

        }

        // Plus / Moins participant
        var min = jQuery('#compteur').attr('min');
        var max = jQuery('#compteur').attr('max');

        function add_field() {
            if (debug == 'true') {
                console.log('call function add_field');
            }
            var i = jQuery('#compteur').val();
            var label = jQuery('.register form .accompagnateur .input label');
            var input = jQuery('.register form .accompagnateur .input input');

            if (input.length) {
                var count = input.length;
            } else {
                var count = 0;
            }

            if (i > count) {
                var total = i - count;
            } else {
                var total = count - i;
            }

            if (i > count) {
                label.css("display", "block");
                for (var a = 0; a < total; a++) {
                    jQuery('.register form .accompagnateur .input').append('<input type="text" name="accompagnateur' + a + '" required="require">');
                    height_step();
                }
            } else {
                for (var a = 0; total > a; total--) {
                    jQuery('.register form .accompagnateur .input input').last().remove();
                }
            }
        }

        jQuery(".register form").on("click", ".participant i", function() {
            var that = jQuery(this);
            var i = jQuery('#compteur').val();
            if (jQuery(that).hasClass('plus')) {
                document.getElementById("compteur").stepUp(1);
                add_field();
                i++;
            } else {
                document.getElementById("compteur").stepDown(1);
                i--;

                jQuery('.register form .accompagnateur .input input').last().remove();

                height_step();

                if (i <= min) {
                    jQuery('.register form .accompagnateur .input label').css("display", "none");
                }
            }

            participant();
        });

        function participant() {
            var i = jQuery('#compteur').val();
            jQuery('.register form #nbCompteur').text(i);
            if (debug == 'true') {
                console.log(i);
            }
            $('input.slider').css('background', 'linear-gradient(to right, red 0%, red ' + i * max + '%, #d3d3d3 ' + i * max + '%, #d3d3d3 100%)');
        }

        jQuery(".register form").on("change", "#compteur", function() {
            var val = jQuery(this).val();
            jQuery(this).attr('value', val);
            jQuery('.register form #nbCompteur').text(val);
            add_field();
        });

        // checkbox paiement accompagnement
        jQuery(".register form").on("click", "#checkbox_accompagnant", function() {
            if (debug == 'true') {
                console.log('click accompagnant');
            }
            jQuery('.register form .accompagnement input').first().attr('required', 'true');
            jQuery('.register form .accompagnement').toggleClass('active');
            jQuery('.register form .accompagnement .input').append('<input type="text" name="nomAccompagnant" data-type="collection" required>');
            setTimeout(function() {
                checkbox();
                calcul_prix();
            }, 150)

        });

        function checkbox() {
            if (jQuery('.register form #checkbox_accompagnant').is(":checked") == false) {
                jQuery('.register form .accompagnement').css({
                    height: '0px',
                    margin: '0px'
                });
                jQuery('.register form .accompagnement').find('input').remove();

            } else {
                var h_accompagnement = jQuery('.register form .accompagnement .input').height();
                jQuery('.register form .accompagnement').css('height', h_accompagnement);
            }

            if (jQuery('.register form #multiple').is(":checked") == true) {
                jQuery('.register form .total .multiple').addClass('active');
                jQuery('.register form .total .devis').removeClass('active');
            } else {
                jQuery('.register form .total .multiple').removeClass('active');
                jQuery('.register form .total .devis').addClass('active');
            }

            jQuery("input[type='checkbox']").each(function(index, el) {
                if (jQuery(el).is(":checked") == true) {
                    jQuery(el).parent().addClass('checked-active');
                    if (debug == 'true') {
                        console.log('true');
                    }
                } else {
                    jQuery(el).parent().removeClass('checked-active');
                }
            });
        }

        function titre(titre_step) {
            jQuery('.register .titre_step').text(titre_step);
        }

        // checkbox options 
        jQuery(".register form").on("click", ".options input", function() {
            calcul_prix();
        });

        // ajout input accompagnement
        count = 1;
        var maxPeople = 0;
        jQuery(".register form").on("click", ".icon.plusone", function() {
            maxPeople = $('#checkbox_accompagnant').attr('data-max');
            count++;
            if (count <= 0) {
                jQuery('.register form .icon.acc.minusone').hide();
            } else {
                jQuery('.register form .icon.acc.minusone').show();
            }

            if (count >= maxPeople) {
                jQuery('.register form .icon.acc.plusone').hide();
            } else {
                jQuery('.register form .icon.acc.plusone').show();
            }

            jQuery('.register form .accompagnement .input').append('<input type="text" name="nomAccompagnant" data-type="collection" required>');
            var h_accompagnement = jQuery('.register form .accompagnement .input').height();
            jQuery('.register form .accompagnement').css('height', h_accompagnement);
            calcul_prix();
            height_step();
        });

        jQuery(".register form").on("click", ".icon.minusone", function() {
            maxPeople = $('#checkbox_accompagnant').attr('data-max');
            count--;
            if (count <= 0) {
                jQuery('.register form .icon.acc.minusone').hide();
            } else {
                jQuery('.register form .icon.acc.minusone').show();
            }

            if (count >= maxPeople) {
                jQuery('.register form .icon.acc.plusone').hide();
            } else {
                jQuery('.register form .icon.acc.plusone').show();
            }

            jQuery('.register form .accompagnement .input').find('input').last().remove();
            var h_accompagnement = jQuery('.register form .accompagnement .input').height();
            jQuery('.register form .accompagnement').css('height', h_accompagnement);
            calcul_prix();
            height_step();
        });

        //gestion du prix 
        jQuery(".register form").on("click", ".type .button", function() {
            if (debug == 'true') {
                console.log('click button type');
            }
            var that = jQuery(this);
            jQuery('.register form .next_step_paiement').addClass('active');
            jQuery('.register .block-button').show();
            height_step();
            jQuery('.register form .type .button').removeClass('active');
            jQuery('.register form .type .button').addClass('transparent');
            jQuery(this).addClass('active');
            jQuery(this).removeClass('transparent');

            calcul_prix();
        });

        // checkbox paiement multiple
        jQuery(".register form").on("click", "#multiple", function() {
            calcul_prix();
            checkbox();
        });

        //code promo trigger 
        jQuery(".register form").on("keyup", "#promo", function() {
            if (debug == 'true') {
                console.log('keyup');
            }
            promo();
        });

        //quand focus out lance la fonction promo (permet de gérer le changement de champs avec tab et d'appliquer le code promo)
        jQuery(".register form").on("focusout", "#promo", function() {
            if (debug == 'true') {
                console.log('focusout');
            }
            setTimeout(function() {
                promo();
            }, 500);
        });

        //select assurance
        jQuery(".register form").on("change", "select[name='compagnieAssurance']", function() {
            if (jQuery(this).val() == "autrecompagnie") {
                jQuery('.register form .autrecompagnie').addClass('active');
            } else {
                jQuery('.register form .autrecompagnie').removeClass('active');
            }
        });

        // radio parcours
        function radiogroup() {
            var radiogroup = jQuery('.register form .input.radio .content-card-radio');

            if (jQuery(radiogroup).length) {
                console.log('find');
                jQuery(radiogroup).click(function(event) {
                    jQuery('.register form .input.radio .content-card-radio').removeClass('active');
                    jQuery(this).addClass('active');
                    var radio = jQuery(this).find('input[type="radio"]');
                    jQuery(radio).prop("checked", true);
                });

            }
        }

        function promo() {
            if (jQuery('.register form #promo').val().length > 3) {
                calcul_prix();
            }
        }

        function label(that) {
            if (jQuery('.register form .type .button.active').length) {
                var type = jQuery('.register form .type .button.active').attr('type');
                var label = jQuery(that).parent().find('label[showprice="true"]');
                if (jQuery(label).length) {
                    var type_label = jQuery(that).attr(type);
                    setTimeout(function() {
                        var variable = jQuery(label).parent().find('.variable');
                        if (!jQuery(variable).length) {
                            jQuery(label).parent().append('<span class="variable"></span>');
                        }
                        setTimeout(function() {
                            variable = jQuery(label).parent().find('.variable');
                            jQuery(variable).text(' (' + type_label + '€)');
                        }, 150);
                    }, 150);
                }
            }
        }

        function calcul_prix() {
            if (debug == 'true') {
                console.log('--------- CALCUL PRIX -----------');
            }
            //adhérent
            var prix = parseInt(0);
            if (jQuery('.register form .type .button.active').length) {
                prix = jQuery('.register form .type .button.active').attr('prix');
                prix = parseInt(prix);
                var type = jQuery('.register form .type .button.active').attr('type');

                if (debug == 'true') {
                    console.log('type : ' + type);
                }

                if (type == 'prix-adherent') {
                    jQuery('.register form #type_adh').val(true);
                    if (debug == 'true') {
                        console.log('set true for #type_adh');
                    }
                } else {
                    jQuery('.register form #type_adh').val(false);
                }

            }

            //accompagnement
            setTimeout(function() {
                label(jQuery('.register form #checkbox_accompagnant'));
            }, 150);

            if (jQuery('.register form #checkbox_accompagnant').is(":checked") == true) {
                var that = jQuery('.register form #checkbox_accompagnant');
                var prix_accompagnant = 0;

                if (jQuery('.register form .type .button').length) {
                    if (debug == 'true') {
                        console.log('find button accompagnant');
                    }
                    if (jQuery('.register form .type .button.active').attr('type').length) {
                        var type = jQuery('.register form .type .button.active').attr('type');
                        var prix_accompagnant = jQuery(that).attr(type);
                    } else {
                        // MANQUE ATTRIBUT TYPE
                        var prix_accompagnant = jQuery(that).attr('prix');
                    }

                } else {
                    // TYPE ADHERENT NON TROUVE
                    var prix_accompagnant = jQuery(that).attr('prix');
                    if (debug == 'true') {
                        console.log('prix accompagnant : ' + prix_accompagnant);
                    }
                }


                jQuery('.register form .accompagnement input').each(function(index, el) {
                    prix += parseInt(prix_accompagnant);
                });
            }

            //option
            jQuery('.register form .options input').each(function(index, el) {
                var that = jQuery(this);
                var prix_option = 0;

                if (jQuery(this).is(":checked") == true) {
                    if (jQuery('.register form .type .button').length) {
                        if (jQuery('.register form .type .button.active').attr('type').length) {
                            var prix_option = jQuery(that).attr(type);
                        } else {
                            // MANQUE ATTRIBUT TYPE
                            var prix_option = jQuery(that).attr('prix');
                        }
                    } else {
                        // TYPE ADHERENT NON TROUVE
                        var prix_option = jQuery(that).attr('prix');
                    }
                    prix += parseInt(prix_option);
                }

                //label
                label(that);
            });

            var multiple = Math.round(prix / 3 * 100) / 100;
            jQuery('.register form .total .devis').text(prix + ' €');
            jQuery('.register form .total .multiple span').text(multiple + ' €');

            //code promo
            var promo = jQuery('.register form #promo').val();
            var remise_value = 0;
            var message_promo = '';
            if (debug == 'true') {
                console.log('enter promo');
                console.log(promo);
            }
            if (promo != undefined && promo != '') {

                // console.log("#### POST-3: "+origin + "/evenements/" + url + "/check/promo")
                $.ajax({
                        method: "POST",
                        url: origin + "/evenements/" + url + "/check/promo",
                        data: { code: promo },
                        dataType: 'json',
                    })
                    .done(function(msg, status) {
                        var code = msg['code'];
                        if (debug == 'true') {
                            console.log(code);
                            console.log(msg['value']);
                        }

                        if (code == '200') {
                            var remise = msg['value'];
                            remise_value = (prix * remise) / 100;
                            var total = prix - remise_value;
                            var multiple = Math.round(total / 3 * 100) / 100;
                            var message_promo = '-' + remise + '% appliqué soit -' + remise_value + '€ sur le total.';
                            jQuery('.register form .total .devis').text(Math.round(total) + ' €');
                            jQuery('.register form .total .multiple span').text(multiple + ' €');
                        } else {
                            if (code == '201') {
                                message_promo = msg['message'];
                            }
                        }

                        if (message_promo != undefined && message_promo != '') {
                            if (jQuery('.register form #promo').parent().find('label.msg').length) {
                                jQuery('.register form label.error_msg').text(message_promo);
                            } else {
                                jQuery('.register form #promo').parent().append('<label class="msg error_msg">' + message_promo + '<label>');
                            }
                        }
                    });
            } else {
                var multiple = Math.round(prix / 3 * 100) / 100;
                jQuery('.register form .total .devis').text(Math.round(prix) + ' €');
                jQuery('.register form .total .multiple span').text(multiple + ' €');
            }
            if (debug == 'true') {
                console.log('--------- END CALCUL PRIX -----------');
            }
        }

        function paiement(step, url, data) {
            if (debug == 'true') {
                console.log('--------- DATA PAIEMENT -----------');
                console.log(data);
                console.log('--------- END DATA PAIEMENT -----------');
            }

            if (step == 6) {
                if (debug == 'true') {
                    console.log('paiement etape 6');
                    console.log('--------- DATA PAIEMENT STEP 6 -----------');
                    console.log(data);
                    console.log('--------- END DATA PAIEMENT STEP 6 -----------');
                }

                $.ajax({
                        method: "POST",
                        url: origin + "/evenements/" + url + "/confirmation",
                        data: {
                            data: data
                        },
                        dataType: 'json',
                    })
                    .done(function(msg, status) {
                        // En fonction du message afficher la fenettre ou non
                        if (debug == 'true') {
                            console.log('--------- DATA PAIEMENT RECEIVE -----------');
                            console.log(msg);
                            console.log('--------- END DATA RECEIVE -----------');
                        }
                        var code = msg['code'];
                        var message = false;

                        if (code == '200') {
                            if (msg.redirect) {
                                window.location = msg.url;
                                return;
                            }

                            // console.log("#### POST-4: ", msg)
                            $.ajax({
                                    method: "POST",
                                    url: msg['url'],
                                })
                                .done(function(retour, status) {
                                    var message = retour['message'];
                                    if (message == 'SUCCESS') {
                                        // POUR LA PROD
                                        var content_form = retour['form'];
                                        // POUR LES TESTS, simule le retour de mercanet
                                        //var content_form = '<FORM METHOD=POST ACTION="https://mercanet.bnpparibas.net:443/cgis-payment-mercanet/demo/callpayment" target="_top"><INPUT TYPE=HIDDEN NAME=DATA VALUE="2020333435603028502c2360532d2334532d2360522c4360502c3360502c2331302d4324575c224360542c3360502c2340522c2360562c3360512d4048502c2328502c2324552c2340522d3340542c5330512d2324512c3324515c224360522e3360502c2329463c4048502c232c502c2360532c3360505c224360502d3360502c2338512c4328562c53402a2c2360562c2360512d2328502c4360502c3328522c3328522d432c585c224360502e2360502c232c592d53402a2c2328582c2360502c4639525c224360502e3360502c2329463c4048502c3324502c2360573a2731543c23484f2b5048502c332c502c2360573a2731543c23484f2b5048502c3338502c23605334552d2c5c224360512d5360502c233946394639463946382a2c2324582c2360502d4360502c2360502c6048502c3344502c2324523b3635523856254e3937304e395645465c224360522c2360502c3331433b56554d39372943383659542b463d49394048502c4324502c23245038363156393729542b463d49394048502c5338502c2324583054284c354445333032512d30352d3431352923303529245c224360572d4360502c2340522c2324532c2338502d5048502d533c502c2360532c3258505c224360572e2360502c232d503a27602aad5d0027c8c9cf65"><DIV ALIGN=center>Vous utilisez le formulaire s&#233;curis&#233; standard SSL, choisissez une carte ci-dessous  <IMG BORDER=0 SRC="/Mercanet/logo/CLEF.gif"> :<br><br></DIV><DIV ALIGN=center><INPUT TYPE=IMAGE NAME=CB BORDER=0 SRC="/Mercanet/logo/CB.gif"><IMG SRC="/Mercanet/logo/INTERVAL.gif"><INPUT TYPE=IMAGE NAME=VISA BORDER=0 SRC="/Mercanet/logo/VISA.gif"><IMG SRC="/Mercanet/logo/INTERVAL.gif"><INPUT TYPE=IMAGE NAME=MASTERCARD BORDER=0 SRC="/Mercanet/logo/MASTERCARD.gif"><IMG SRC="/Mercanet/logo/INTERVAL.gif"><INPUT TYPE=IMAGE NAME=PAYLIB BORDER=0 SRC="/Mercanet/logo/PAYLIB.gif"><br><br></DIV></FORM>';

                                        jQuery('.register form .content_step').find('.step').removeClass('active');

                                        setTimeout(function() {
                                            jQuery('.register form .content_step').append('<section class="step mercanet" vide="false" step="7" title="Paiement">' + content_form + '</section>');
                                            height_step();
                                            setTimeout(function() {
                                                jQuery('.register form .content_step .step').last().addClass('active');
                                                jQuery('.register .next_step').hide();

                                                const paymentForm = jQuery('#payment-form');
                                                const submitForm = paymentForm.find('#submit-payment');

                                                submitForm.click(function() {
                                                    paymentForm.submit();
                                                });
                                            }, 200);
                                        }, 100);
                                    } else {
                                        message = "Une erreur c\'est produite, veuillez contacter <a href='mailto:club14@club14.com'>club14@club14.com</a> ";
                                    }
                                });
                        } else {
                            if (code == '201') {
                                // ERREUR FIELD
                                message = msg['message'];
                                message_back(msg['data']);
                            } else {
                                // AUTRE ERREUR
                                message = msg['message'] + ' ' + code;
                            }
                        }

                        msg_error(message);
                    });
            }

        }
    }

});