$(document).ready(function() {
	var city_timeout = false;

	var cp_input = null;
	var city_select = null;

	var form = $('#subscribe-form');

	form.on('keyup', 'input[name=cp]', function(e) {
		if (!cp_input) {
			cp_input = $('input[name=cp]');
			city_select = $('select[name=ville]');
		}

		var value = cp_input.val();
		if (value.length < 2) {
			return;
		}

		var cp = value.trim();
		if (!cp.length || !cp.match('[0-9]{1,5}')) {
			return;
		}

		if (city_timeout) {
			clearTimeout(city_timeout);
		}

		city_timeout = setTimeout(function() {
			$.ajax({
				method: 'post',
				url: '/ajax/list-cities',
				data: {
					cp: cp,
					limit: 20
				},

				beforeSend: function() {
					city_select.empty();
					city_select.append('<option value="">Chargement des villes en cours...</option>');
				},

				success: function(result) {
					city_select.empty();

					if (!result.cnt) {
						city_select.append('<option value="">Aucune ville trouvée</option>');
						return;
					}
					else if (result.cnt > 1) {
						city_select.append('<option value="">Veuillez selectionner votre ville</option>');
					}

					result.data.map(function(ville) {
						city_select.append('<option value="'+ville.id+'" data-cp="'+ville.cp+'">'+ville.cp+' - '+ville.nom+'</option>');
						if (result.cnt == 1) {
							cp_input.val(ville.cp);
						}
					});
				},

				error: function(err) {
					console.error(err);
				}
			})
		}, 500);
	}).keyup();


	form.on('change', 'select[name=ville]', function() {
		if (!cp_input) {
			cp_input = $('input[name=cp]');
			city_select = $('select[name=ville]');
		}
		
		var cp = $(this).find('option:selected').attr('data-cp');
		if (!cp) {
			return;
		}

		cp_input.val(cp);
	});

	var cp =form.find('input[name=cp]');
	if (cp) {
		cp.keyup();
	}
});